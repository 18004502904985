<!--
 * @Author: 熊志伟
 * @Date: 2023-12-08 10:08:59
 * @LastEditors: 熊志伟
 * @LastEditTime: 2023-12-12 18:47:26
 * @FilePath: \dcy-web\apps\learning-space\layouts\personal.vue
 * @Description: 个人中心layout
-->
<script lang="ts" setup>
const userStore = useUserStore()
const router = useRouter()
const data = reactive({
  currentItem: '',
  sideList: [
    {
      name: '个人资料',
      path: '/personal',
    },
    {
      name: '修改密码',
      path: '/personal/change-password',
    },
    {
      name: '退出登录',
    },
  ],
})
watch(() => router.currentRoute.value.path, (toPath) => {
  // 要执行的方法
  data.currentItem = toPath
}, { immediate: true, deep: true })

function toast() {}

function logout() {
  const cb = () => {
    navigateTo('/login')
  }
  userStore.logout(cb)
}
function gotoLink(path: string | undefined) {
  if (path) {
    router.push(path)
    return
  }
  logout()
}
</script>

<template>
  <div class="personal-wrapper">
    <div class="side-box">
      <div class="avatar-box">
        <a-avatar
          :auto-fix-font-size="false"
          src="/vite.png"
          @click="toast"
        >
          <template #trigger-icon>
            <IconCamera />
          </template>
        </a-avatar>
        <div mt-3>
          周庆云
        </div>
      </div>
      <ul w-full>
        <li v-for="item in data.sideList" :key="item.name" class="side-item" :class="{ active: data.currentItem === item.path }" @click="gotoLink(item.path)">
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="main-container">
      <slot />
    </div>
  </div>
</template>

<style lang="less" scoped>
.personal-wrapper {
  --at-apply: flex align-start;
  height: calc(100vh - 88px)
}
.side-box {
  --at-apply: w-70 h-full bg-white flex flex-col items-center mr-5;

  .avatar-box {
    --at-apply: pt-10 pb-5 w-full flex flex-col items-center;
    border-bottom: 1px solid #ccc;
  }
  .side-item {
    --at-apply: py-5 pl-10 w-full cursor-pointer;
    border-bottom: 1px solid #ccc;
    &.active {
      color: #6B6AFF;
    }
    &:hover {
      .active;
    }
  }
}
.main-container {
  --at-apply: flex-1 h-full;
}
</style>
